import { useContext, useEffect, useState } from "react";
import { Box, Flex, Text, TransparentButton } from "flicket-ui";
import { Status } from "~components";
import { BaseContext } from "~context";
import { Layout } from "~components/common/Layout";
import { EventAccessStatus, EventQuery } from "~graphql/sdk";
import { Desktop } from "./detail.Desktop";
import { Mobile } from "./detail.Mobile";
import MainCTA from "../events.MainCTA";
import useShowReleaseCodes from "~features/events/hooks/useShowReleaseCodes";
import useDetailsPageContext, {
  competitionFromPageContext,
} from "~features/events/hooks/useDetailsPageContext";
import { Release } from "~graphql/fetchers";
import { useActiveCompetitions } from "~features/events/hooks/useActiveCompetitions";
import ReleaseCodesModal from "../ReleaseCodesModal";
import usePageLoad from "~telemetry/traces/usePageLoad";

import { AttributeNames, PageName } from "~telemetry/enums/AttributeNames";
import { useGoogleAnalyticsPageView } from "~hooks/useGoogleAnalyticsPageView";
import Router from "next/router";
import EventHeader from "~components/common/common.EventHeader";
import { useIsMobile } from "~hooks";
import { converge } from "~lib/seeka";

export type DetailsProps = {
  event: EventQuery["event"];
  release?: Release;
  accessType: EventAccessStatus;
};

export const Detail = ({ event, release }: DetailsProps) => {
  const { activeCompetitions, isLoading } = useActiveCompetitions(event.id);
  const pageContext = useDetailsPageContext(event, release, activeCompetitions);
  const [activeModal, setActiveModal] = useState<"releaseCodes">();
  const isMobile = useIsMobile();

  const { isSinglePage, organization } = useContext(BaseContext);
  const showReleaseCodes = useShowReleaseCodes(event.enableReleaseCodes);

  const competitionId = competitionFromPageContext(pageContext)?.id;

  const footerHeight = showReleaseCodes ? "110px" : "70px";

  useGoogleAnalyticsPageView(
    !!event?.id,
    PageName.EVENT_DETAILS_PAGE,
    event?.id
  );

  usePageLoad(
    PageName.EVENT_DETAILS_PAGE,
    {
      forwardToNest: true,
      eventId: event.id,
      attributes: {
        [AttributeNames.FLICKET_EVENT_DETAILS_PAGE_STATE]: pageContext.state,
        [AttributeNames.FLICKET_COMPETITION_ID]: competitionId,
      },
    },
    // Only fire the event after the active competitions have finished loading so that the pageContext is correct
    !isLoading
  );

  useEffect(() => {
    void converge?.track?.viewProduct({
      productIdentifier: event?.id,
      productName: event?.title,
    });
  }, []);

  return (
    <Status loading={isLoading} error={false}>
      {/* Mobile */}
      {isMobile && (
        <Box
          d={{ _: "flex", md: "none" }}
          flexDirection="column"
          flex={1}
          pt={isSinglePage ? 15 : 0}
        >
          {!isSinglePage && (
            <EventHeader
              type="event"
              title={event.title}
              startDate={event?.startDate}
              endDate={event?.endDate}
              venue={event?.venue}
              goBack={() => void Router.push("/")}
              accessibility={event?.accessibility}
              supportInfo={organization?.supportInfo}
              image={event?.logo}
              description={event?.description}
              coordinates={`${event?.venue?.location?.lat},${event?.venue?.location?.lng}`}
            />
          )}
          <Box
            display={"flex"}
            flex={1}
            flex-direction={"column"}
            position={"relative"}
            width={"100%"}
            maxWidth={"100vw"}
            pt={isSinglePage ? 0 : 6}
            paddingBottom={footerHeight as any}
          >
            <Mobile pageContext={pageContext} />
          </Box>
          <Flex
            height={footerHeight}
            flexDirection={"column"}
            justifyContent={"center"}
            position={"fixed"}
            bottom={0}
            left={0}
            right={0}
            px={2}
            py={1}
            background="white"
            borderTop={"1px solid"}
            borderColor={"N200"}
          >
            <MainCTA pageContext={pageContext} />
            {showReleaseCodes && (
              <TransparentButton onClick={() => setActiveModal("releaseCodes")}>
                <Text
                  fontWeight={"extraBold"}
                  fontSize={3}
                  color="N800"
                  my={"6/4"}
                >
                  Have an access code?
                </Text>
              </TransparentButton>
            )}
          </Flex>
        </Box>
      )}
      {/* Desktop */}
      {!isMobile && (
        <Box d={{ _: "none", md: "flex" }} flexDirection="column" flex={1}>
          <Layout layoutType="main">
            <Desktop
              pageContext={pageContext}
              event={event}
              isSinglePage={isSinglePage}
            />
          </Layout>
        </Box>
      )}

      <ReleaseCodesModal
        isOpen={activeModal === "releaseCodes"}
        onClose={() => setActiveModal(undefined)}
        event={event}
      />
    </Status>
  );
};
